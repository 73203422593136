import { Container } from '../layout/container'


export function SecondaryFeatures({pageDetail}) {
  return (
    <section
      id="about-us"
      aria-label="Features for building a portfolio"
      className="pt-20"
    >
      <Container>
        <div className="mx-auto max-w-7xl sm:text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900">
              {pageDetail.title}
          </h2>
          <p className="mt-2 text-md font-light text-gray-600">
              {pageDetail.description}
          </p>
        </div>
      </Container>
    </section>
  )
}
