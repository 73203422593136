import {Footer} from "../../components/layout/footer";
import {Navigation} from "../../components/layout/navigation";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";


function GenericPage({Storyblok}) {

    const [pageDetail, setPageDetail] = useState(false);
    let navigate = useNavigate();

    let {page}  = useParams();


    useEffect(() => {
        Storyblok.get("cdn/stories/pagine/"+page)
            .then(response => {
                setPageDetail(response.data.story);
            }).catch(error => {
                    navigate("/")
        });

    }, [page, Storyblok, navigate]);


  return (
      <div className="bg-white scroll-smooth">
          <Navigation fixed={true}/>


          <div className="mx-auto max-w-7xl px-6 my-10">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{pageDetail?.content?.contenuto[0]?.title ?? ''}</h2>
              <div className="mt-6  richText" dangerouslySetInnerHTML={{__html:  Storyblok.richTextResolver.render(pageDetail?.content?.contenuto[0]?.description ?? [])}} />
          </div>



          <Footer Storyblok={Storyblok} fixed={true} />
      </div>
  );
}






export default GenericPage;
