/* This example requires Tailwind CSS v2.0+ */
import React, {useState} from "react";
import {BellAlertIcon} from "@heroicons/react/24/outline";

export default function Cookie() {

    const [status, setStatus] = useState(localStorage.getItem('privacy_status'));

    return (

        !status && (
            <>

                <div className="fixed inset-x-0 bottom-0">
                    <div className="bg-red-600">
                        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                            <div className="flex items-center justify-between flex-wrap">
                                <div className="w-0 flex-1 flex items-center">
                                <span className="flex p-2 rounded-lg bg-red-800">
                                  <BellAlertIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                </span>
                                    <p className="ml-3 font-medium text-white ">
                                    <span>

                                        Noi e terze parti selezionate utilizziamo cookie o tecnologie simili per finalità tecniche e, con il tuo consenso, anche per le finalità di misurazione come specificato nella  <a href="https://www.iubenda.com/privacy-policy/32702144" className="underline">Cookie policy </a>. Il rifiuto del consenso può rendere non disponibili le relative funzioni.
                                        Usa il pulsante “Accetta” per acconsentire. Chiudi questa informativa per continuare senza accettare.

                                    </span>
                                    </p>
                                </div>

                                <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                                    <button
                                        onClick={() => {
                                            localStorage.setItem('privacy_status', true);
                                            setStatus( true);

                                        }}
                                        type="button"
                                        className="text-white -mr-1 flex p-2 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                                    >
                                        <b>Accetta</b>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )


    )
}
