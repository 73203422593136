import {useEffect, useState} from "react";
import {Button} from "./button";

export function CallToAction({pageDetail}) {


    const [imageURL, setImageURL] = useState(null);

    useEffect(() => {
        fetch('https://minigoal.skemasoft.com/api/public/magazines?limit=1&offset=0')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Errore nella chiamata API');
                }
                return response.json();
            })
            .then((jsonData) => {
                // Estraiamo l'URL dell'immagine del primo "minigoal"
                const firstImageURL = jsonData.magazines[0]?.picture?.reference;
                setImageURL(firstImageURL);
            })
            .catch((error) => {
                console.error('Si è verificato un errore:', error);
            });
    }, []);

  return (



      <section id="last-minigoal" className="relative isolate bg-red-600">
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="mx-auto flex max-w-2xl flex-col gap-16  px-6 py-16  sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
                  <img
                      className=" w-full flex-none rounded-2xl object-fill shadow-xl  lg:h-auto lg:max-w-xs"
                      src={imageURL}
                      alt=""
                  />
                  <div className="w-full flex-auto">
                      <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                          {pageDetail.title}
                      </h2>
                      <p className="mt-6 text-lg leading-8 text-white">
                          {pageDetail.description}
                      </p>


                      <div className="mt-10 flex items-center justify-start gap-x-6">
                          <Button
                              href="http://app.minigoal.it/"
                              variant="outline"
                              color="white"
                          >
                              <span>Acquista ora!</span>
                          </Button>
                      </div>

                  </div>
              </div>
          </div>
          <div
              className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
              aria-hidden="true"
          >
              <div
                  className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#F3F4F6] to-[#111827] opacity-25"
                  style={{
                      clipPath:
                          'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                  }}
              />
          </div>
      </section>
  )
}
