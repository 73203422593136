import clsx from 'clsx'
import downAndroid from "../../resource/images/down-android.png";

export function AndroidStoreLink({ link, color = 'black' }) {
  return (
      <a
          href={link}
          aria-label="Download on the App Store"
          className={clsx(
              'rounded-lg transition-colors',
              color === 'black'
                  ? 'bg-gray-800 text-white hover:bg-gray-900'
                  : 'bg-white text-gray-900 hover:bg-gray-50'
          )}
      >
        <img src={downAndroid} className="h-10" alt={'android'} />
      </a>
  )
}
