import { Container } from '../layout/container'

export function Faqs({title, pageDetail}) {


  const column1 = [];
  const column2 = [];
  const column3 = [];

  (pageDetail?.faq_detail ?? []).forEach((item, index) => {
    switch (index % 3) {
      case 0:
        column1.push(item);
        break;
      case 1:
        column2.push(item);
        break;
      case 2:
        column3.push(item);
        break;
      default:
        break;
    }
  });



  return (
    <section
      id="faqs"
      aria-labelledby="faqs-title"
      className="border-t border-gray-200 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faqs-title"
            className="text-3xl font-medium tracking-tight text-gray-900"
          >
            {title.title}
          </h2>
          <p className="mt-2 text-lg text-gray-600">
            {title.description}
          </p>
        </div>
        <ul
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 sm:mt-20 lg:max-w-none lg:grid-cols-3"
        >

          <li>
            <ul className="space-y-10">

            {
              column1.map((item, index) => (
                <li key={item.id}>
                  <h3 className="text-lg font-semibold leading-6 text-gray-900">
                    {item.title}
                  </h3>
                  <p className="mt-4 text-sm text-gray-700">{item.description}</p>
                </li>
              ))
            }
            </ul>
          </li>

          <li>
            <ul className="space-y-10">

              {
                column2.map((item, index) => (
                    <li key={item.id}>
                      <h3 className="text-lg font-semibold leading-6 text-gray-900">
                        {item.title}
                      </h3>
                      <p className="mt-4 text-sm text-gray-700">{item.description}</p>
                    </li>
                ))
              }
            </ul>
          </li>

          <li>
            <ul className="space-y-10">

              {
                column3.map((item, index) => (
                    <li key={item.id}>
                      <h3 className="text-lg font-semibold leading-6 text-gray-900">
                        {item.title}
                      </h3>
                      <p className="mt-4 text-sm text-gray-700">{item.description}</p>
                    </li>
                ))
              }
            </ul>
          </li>

        </ul>
      </Container>
    </section>
  )
}
