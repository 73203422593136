'use client'

import { useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { useInView } from 'framer-motion'

import { Container } from '../layout/container';


function Image({ image, className, ...props }) {
  let animationDelay = useMemo(() => {
    let possibleAnimationDelays = ['0s', '0.1s', '0.2s', '0.3s', '0.4s', '0.5s']
    return possibleAnimationDelays[
      Math.floor(Math.random() * possibleAnimationDelays.length)
    ]
  }, [])

  return (
    <img
        src={image}
      className={clsx(
        'animate-fade-in rounded-3xl bg-white p-2 opacity-0 shadow-md shadow-gray-900/5',
        className
      )}
      style={{ animationDelay }}
      {...props}
        alt=""
    />
  )
}

function splitArray(array, numParts) {
  let result = []
  for (let i = 0; i < array.length; i++) {
    let index = i % numParts
    if (!result[index]) {
      result[index] = []
    }
    result[index].push(array[i])
  }
  return result
}




function ReviewColumn({
  className,
  galleries,
  reviewClassName = () => {},
  msPerPixel = 0,
}) {
  let columnRef = useRef()
  let [columnHeight, setColumnHeight] = useState(0)
  let duration = `${columnHeight * msPerPixel}ms`

  useEffect(() => {
    let resizeObserver = new window.ResizeObserver(() => {
      setColumnHeight(columnRef.current.offsetHeight)
    })

    resizeObserver.observe(columnRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <div
      ref={columnRef}
      className={clsx('animate-marquee space-y-8 py-4', className)}
      style={{ '--marquee-duration': duration }}
    >
      {galleries.map((img, index) => (
        <Image
          key={index}
          image={img}
          aria-hidden={index >= galleries.length}
          className={reviewClassName(index % galleries.length)}
        />
      ))}
    </div>
  )
}

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

function ReviewGrid({images}) {
  let containerRef = useRef()
  let isInView = useInView(containerRef, { once: true, amount: 0.4 });

  const filenames = (images ?? []).map(item => item.filename);
  const duplicatedFilenames = [...filenames, ...filenames, ...filenames];
  const shuffledFilenames = shuffleArray(duplicatedFilenames);
  let columns = splitArray(shuffledFilenames, 3)
  columns = [columns[0], columns[1], splitArray(columns[2], 2)]

  return (
    <div
      ref={containerRef}
      className="relative bg-gray-100 -mx-4 mt-16 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3"
    >
      {isInView && (
        <>
          <ReviewColumn
              galleries={[...columns[0], ...columns[2].flat(), ...columns[1]]}
              reviewClassName={(reviewIndex) =>
                clsx(
                 reviewIndex >= columns[0].length + columns[2][0].length &&
                  'md:hidden',
                  reviewIndex >= columns[0].length && 'lg:hidden'
                )
              }
            msPerPixel={10}
          />
          <ReviewColumn
              galleries={[...columns[1], ...columns[2][1]]}
            className="hidden md:block"
            reviewClassName={(reviewIndex) =>
              reviewIndex >= columns[1].length && 'lg:hidden'
            }
            msPerPixel={15}
          />
          <ReviewColumn
            galleries={columns[2].flat()}
            className="hidden lg:block"
            msPerPixel={10}
          />
        </>
      )}
      <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-100" />
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-gray-100" />
    </div>
  )
}

export function Gallery({pageDetail}) {

  return (
    <section
      id="reviews"
      aria-labelledby="reviews-title"
      className="pb-16 -mt-8"
    >
      <Container>

        {
          (pageDetail?.images?.length > 0 ?? false) && (
                <ReviewGrid images={pageDetail.images} />
            )
        }


      </Container>
    </section>
  )
}
