import React from "react";
import { Route, Routes } from "react-router";
import Homepage from "./pages/homepage";
import GenericPage from "./pages/generic-page";

import StoryblokClient from 'storyblok-js-client';
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";


function Main() {


    const Storyblok = new StoryblokClient({
        accessToken: 'oKR2zaroOBIr04XnMQtYXwtt',
    });


    return (
    <Routes>
        <Route path="/" element={<Homepage Storyblok={Storyblok} />} />
        <Route path="/page/privacy" element={<Privacy />} />
        <Route path="/page/terms" element={<Terms />} />
        <Route path="/page/:page" element={<GenericPage Storyblok={Storyblok} />} />

        {/*

        <Route path="/contact-us" element={<ContactUs  />} />
        <Route path="/quote-request" element={<QuoteRequest  />} />


        */}

    </Routes>
  );
}

export default Main;
