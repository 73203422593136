import { Dialog, Transition } from '@headlessui/react'
import React, {Fragment, useEffect, useState} from 'react'
import {XMarkIcon} from "@heroicons/react/24/outline";


const NewsPopup = ({Storyblok}) => {

    const [pageDetail, setPageDetail] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [newsDetail, setDetail] = useState(false);


    useEffect(() => {
        if (pageDetail.length === 0){
            Storyblok.get(`cdn/stories/home-page/news`)
                .then(response => {
                    setPageDetail(response?.data?.story?.content?.contenuto ?? false);
                }).catch(error => {
                console.error('Error fetching story:', error);
            });
        }
    }, [Storyblok, pageDetail]);



    return (

        <section id="last-news" className="relative isolate">

            {
                pageDetail.filter(r => r.public).length > 0 && (
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl lg:mx-0 mb-5">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 text-center lg:text-left sm:text-4xl">News</h2>
                        </div>


                        <div className="mx-auto mb-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-5 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                            {pageDetail.filter(r => r.public).map((news) => (
                                <article key={news._uid} className="flex max-w-xl flex-col items-start justify-between hover:bg-gray-200 p-2 rounded-md cursor-pointer"
                                    onClick={() => {
                                        setDetail(news);
                                        setIsOpen(true);
                                    }}
                                >
                                    <div className="flex items-center gap-x-4 text-xs">
                                        <time className="text-gray-500">
                                            {news?.date ?? ''}
                                        </time>
                                    </div>
                                    <div className="group relative">
                                        <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                            <span className="absolute inset-0" />
                                            {news.title}
                                        </h3>
                                        <div className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600" dangerouslySetInnerHTML={{ __html: Storyblok.richTextResolver.render(news?.description ?? []) }} />
                                    </div>
                                </article>
                            ))}
                        </div>
                    </div>
                )
            }

            
        
            
            
            {
                isOpen && (
                    <Layout
                        setIsOpen={async (r) => {
                            setIsOpen(r);
                        }}
                        isOpen={isOpen}
                    >

                       
                        <div className="text-base  text-gray-700 p-10 -mt-10">
                            <div className="flex items-center gap-x-4 text-xs">
                                <time className="text-gray-500">
                                    {newsDetail?.date ?? ''}
                                </time>
                            </div>
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900 text-center lg:text-left sm:text-4xl">
                                {newsDetail.title}
                            </h1>
                            <p className="mt-6" dangerouslySetInnerHTML={{ __html: Storyblok.richTextResolver.render(newsDetail?.description ?? []) }} />
                        </div>



                    </Layout>
                )
            }
        </section>



       

    )
}


const Layout = ({isOpen, setIsOpen, children}) => {


    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={setIsOpen}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >&#8203;</span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-4xl my-8 text-left align-middle transition-all transform bg-white ">

                            <div className="flex justify-between    items-center">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-blue-1000">
                                    </h3>
                                </div>
                                <div>
                                    <button className=" border-0" onClick={() => {setIsOpen(false)}}><XMarkIcon className="w-6 h-6 p-1 fill-current rounded-full bg-white  text-red-600" /></button>
                                </div>
                            </div>


                            {children}


                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )

}

export default NewsPopup;
