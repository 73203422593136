import { Dialog, Transition } from '@headlessui/react'
import React, {Fragment, useEffect, useState} from 'react'
import {XMarkIcon} from "@heroicons/react/24/outline";


const AdsPopup = ({Storyblok}) => {

    const [pageDetail, setPageDetail] = useState(false);
    const [isOpen, setIsOpen] = useState(false);


    useEffect(() => {
        if(!pageDetail){
            Storyblok.get(`cdn/stories/home-page/publicita`)
                .then(response => {

                    if(response.data.story.content.contenuto[0] && (response.data.story.content.contenuto[0].video || response.data.story.content.contenuto[0].picture)){
                        setIsOpen(true);
                    }

                    setPageDetail(response?.data?.story?.content?.contenuto[0] ?? false);
                }).catch(error => {
                console.error('Error fetching story:', error);
            });
        }
    }, [Storyblok, pageDetail]);




    return (
        pageDetail && (
            <Layout
                title={'asd'}
                setIsOpen={async (r) => {
                    setIsOpen(r);
                }}
                isOpen={isOpen}
            >

                <a href={pageDetail.link} target="_blank" rel="noreferrer">
                    {
                        pageDetail.video ? (
                            <video autoPlay controls muted name="media">
                                <source src={pageDetail.video} type="video/mp4"/>
                            </video>

                        ) : (
                            <img src={pageDetail.picture} className="h-full w-full"  alt={''}/>

                        )
                    }
                </a>




            </Layout>
        )

    )
}


const Layout = ({title, isOpen, setIsOpen, children}) => {


    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={setIsOpen}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >&#8203;</span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-4xl my-8 text-left align-middle transition-all transform ">

                            <div className="flex justify-between    items-center">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-blue-1000">
                                        {title}
                                    </h3>
                                </div>
                                <div>
                                    <button className=" border-0" onClick={() => {setIsOpen(false)}}><XMarkIcon className="w-6 h-6 p-1 fill-current rounded-full bg-white  text-red-600" /></button>
                                </div>
                            </div>


                            {children}


                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )

}

export default AdsPopup;
