'use client'

import { HashLink as Link } from 'react-router-hash-link';
import { Popover } from '@headlessui/react'
import { AnimatePresence, motion } from 'framer-motion'

import Logo from '../../resource/images/logo.png'
import { NavLinks } from '../common/nav-links'

function MenuIcon(props) {
    return (
        <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
            <path
                d="M5 6h14M5 18h14M5 12h14"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

function ChevronUpIcon(props) {
    return (
        <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
            <path
                d="M17 14l-5-5-5 5"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

function MobileNavLink({ children, ...props }) {
    return (
        <Link
            to={props.href}
            className="block text-base leading-7 tracking-tight text-gray-700"
            {...props}
        >
            {children}
        </Link>
    )
}

export function Navigation({fixed = false}) {
    return (
        <header className={fixed ? 'border-b border-gray-100 bg-white' :''}>
            <nav>
                <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:px-8 relative z-50 flex justify-between ">
                    <div className="relative z-10 flex items-center gap-16">
                        <a href="/" >
                            <img src={Logo}  className="h-10 w-auto" alt="" />
                        </a>
                        <div className="hidden lg:flex lg:gap-10">
                            <NavLinks />
                        </div>
                    </div>
                    <div className="flex items-center gap-6">
                        <Popover className="lg:hidden">
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        className="relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 ui-not-focus-visible:outline-none"
                                        aria-label="Toggle site navigation"
                                    >
                                        {({ open }) =>
                                            open ? (
                                                <ChevronUpIcon className="h-6 w-6" />
                                            ) : (
                                                <MenuIcon className="h-6 w-6" />
                                            )
                                        }
                                    </Popover.Button>
                                    <AnimatePresence initial={false}>
                                        {open && (
                                            <>
                                                <Popover.Overlay
                                                    static
                                                    as={motion.div}
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    exit={{ opacity: 0 }}
                                                    className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                                                />
                                                <Popover.Panel
                                                    static
                                                    as={motion.div}
                                                    initial={{ opacity: 0, y: -32 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    exit={{
                                                        opacity: 0,
                                                        y: -32,
                                                        transition: { duration: 0.2 },
                                                    }}
                                                    className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20"
                                                >
                                                    <div className="space-y-4">
                                                        <MobileNavLink href="/#last-minigoal">
                                                            Nuove Uscite
                                                        </MobileNavLink>
                                                        <MobileNavLink href="/#about-us">
                                                            Chi siamo
                                                        </MobileNavLink>
                                                        <MobileNavLink href="/#contact-us">
                                                            Contattaci
                                                        </MobileNavLink>
                                                        <MobileNavLink href="/#faqs">FAQs</MobileNavLink>
                                                    </div>
                                                    <div className="mt-8 flex flex-col gap-4">
                                                        <a href="https://app.minigoal.it/login" className="inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors border-gray-300 text-gray-700 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80">
                                                            Accedi
                                                        </a>
                                                        <a href="https://app.minigoal.it/register" className="inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors bg-gray-800 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white/80">Registrati</a>
                                                    </div>
                                                </Popover.Panel>
                                            </>
                                        )}
                                    </AnimatePresence>
                                </>
                            )}
                        </Popover>
                        <a href="https://app.minigoal.it/login" className="inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors border-gray-300 text-gray-700 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80 hidden lg:block">
                            Accedi
                        </a>
                        <a href="https://app.minigoal.it/register" className="hidden inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors bg-gray-800 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white/80 lg:block">
                            Registrati
                        </a>
                    </div>
                </div>
            </nav>
        </header>
    )
}
